<script lang="ts">
import Dialog from "../components/Dialog.vue";
import IntegrationCode from "../components/IntegrationCode.vue";

import { getUser } from "../user";
import Badge from "@/components/Badge.vue";

export default {
  inject: ['adminBackend'],
  components: {
    Badge,
    IntegrationCode,
    Dialog,
  },
  data() {
    return {
      domains: [],
      usages: {},
      newDomain: '',
      plans: [],
      plan: 'Trial',
      user: {},
      isLoading: false,
    }
  },
  mounted() {
    this.initializeDomains()
    this.user = getUser()
  },
  methods: {
    initializeDomains() {
      this.isLoading = true
      this.adminBackend().listDomains().then((domains) => {
        this.domains.push(...domains)
      })
        .finally(() => {
          this.isLoading = false
        })
      this.adminBackend().getPlans().then((plans) => {
        this.plans.push(...plans)
      })
    },
    loadUsagesForDomain(domain) {
      if (!(domain.name in this.usages)) {
        this.adminBackend().getUsagesForDomain(domain.name).then((usages) => {
          this.usages[domain.name] = usages
        })
      }
    },
    createDomain() {

      const createDomainRoute = this.$router.resolve({ name: 'create-domain', params: {
        domain: this.newDomain, plan: this.plan,
      }});

      const successUrl = new URL(createDomainRoute.href, window.location.href);
      successUrl.searchParams.set('result', 'paid')

      if(this.plan == 'Trial') {
        // successUrl.searchParams.set('subscriptionId', '')
        window.location.href = successUrl.href
      } else {
        const cancelUrl = new URL(createDomainRoute.href, window.location.href);
        cancelUrl.searchParams.set('result', 'cancel')
        this.adminBackend().createCheckoutSession(
          this.newDomain, this.plan, getUser()?.email,
          successUrl.href+'&sessionId={CHECKOUT_SESSION_ID}',
          cancelUrl.href,
        ).then((response) => {
          window.location.href = response['checkoutUrl']
        })
      }
    },
    openSubscriptionPortal(domain) {
      this.adminBackend().getSubscriptionManagementUrl(
        domain.subscriptionId, window.location.href,
      ).then((response) => {
        window.location.href = response['subscriptionUrl']
      })

    },
    formatPrice(price) {
      const cents = price % 100
      const euros = price / 100
      return `${euros}.${String(cents).padStart(2, '0')} €`
    }
  },
  computed: {
    isDomainInvalid() {
      return ! /^(((?!\-))(xn\-\-)?[a-z0-9\-_]{0,61}[a-z0-9]{1,1}\.)*(xn\-\-)?([a-z0-9\-]{1,61}|[a-z0-9\-]{1,30})\.[a-z]{2,}$/.test(this.newDomain)
    }
  }
}
</script>

<template>
  <div class="headings">
    <h2>My domains</h2>
    <h3>Shows all domains that are owned by this account.</h3>
  </div>
  <nav class="breadcrumbs">
    <ul>
      <li><router-link :to="{ name: 'domains' }">Domains</router-link></li>
    </ul>
  </nav>
  <article aria-busy="true" v-if="isLoading"></article>
  <article v-for="domain in domains">
    <header>
      <Badge v-if="domain.expired">expired</Badge>{{ domain.name }} ({{ domain.plan }})
    </header>

    <details>
      <summary @click="loadUsagesForDomain(domain)">Usage</summary>
      <table role="grid">
        <thead>
        <tr>
          <th scope="col">Month</th>
          <th scope="col">Plan</th>
          <th scope="col">Requests (remaining quota) </th>
          <th scope="col">Requests (used)</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="usage in usages[domain.name]">
          <th scope="row">{{ usage.year }} - {{ usage.month }}</th>
          <td>{{ usage.plan }}</td>
          <td class="number">{{ usage.requestsQuota }}</td>
          <td class="number">{{ usage.requestsUsed }}</td>
        </tr>
        </tbody>
      </table>
      <small>
        The shown statistics are not current, but are gathered on a regular
        schedule and therefore lag behind the current consumption.
      </small>
    </details>

    <details>
      <summary>API key / integration</summary>
      <IntegrationCode :api-key="domain.apiKey"></IntegrationCode>
    </details>
    <footer>
      <div class="grid">
        <router-link :to="{ name: 'collections', params: {domain: domain.name } }" role="button">List collections</router-link>
<!--        <router-link :to="'/domains/${domain.name}/collections'" role="button">Moderate comments</router-link>-->
<!--        <router-link :to="'/domains/${domain.name}/collections'" role="button" class="dangerous">Delete domain</router-link>-->
        <a href="#" @click.prevent="openSubscriptionPortal(domain)" role="button" v-if="domain.subscriptionId != ''">Manage subscription</a>
      </div>
    </footer>
  </article>

  <article v-if="! user.isTrialUser">
    <header>
      Create new domain
    </header>

    <form>

      <label for="domain">Domain</label>
      <input id="domain" name="domain" placeholder="domain.tld" v-model="newDomain" required>
      <small>We need the domain name of the website which will contain the comments.</small>

      <!-- Markup example 2: input is after label -->
      <label for="plan">Plan</label>
      <select id="plan" v-model="plan" required>
        <option value="Trial" selected>Trial (Free)</option>
        <option :value="plan.plan" v-for="plan in plans">{{ plan.plan }} ({{ formatPrice(plan.price) }}*)</option>
      </select>
      <small>
        *The shown prices are net, VAT excluded, per month. <br>
        A description of the individual plans can be found under
        <a href="https://glossa.cc/pricing" target="_blank">https://glossa.cc/pricing</a>.
      </small>

      <button type="submit" @click.prevent="createDomain" :disabled="isDomainInvalid">Create domain</button>
    </form>
  </article>
</template>

<style scoped>
summary {
  height: 2em;
}

details[open] {
  margin-bottom: 3em;
}

</style>
